@import "../../Assets/styleVariables.scss";

.projectIcon,
.experienceIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    height: 3rem;
    width: 3rem;
    margin-bottom: 1rem;
  }

  .name {
    text-transform: uppercase;
    font-size: 2rem;
  }
}

.footerIcon {
  .icon {
    height: 4rem;
    width: 4rem;
    margin-bottom: 1rem;
  }
  .name {
    height: 0;
    width: 0;
    opacity: 0;
  }
  &:hover {
    cursor: pointer;
  }
}
