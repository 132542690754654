.carouselContainer {
  overflow: hidden;
  position: relative;
  height: 50rem;
  width: 100rem;
  margin: auto;
  margin-bottom: 2rem;
  z-index: -1;

  .carouselTrack {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 50rem;
    gap: 26px;
    height: 100%;
    animation: slide 25s linear infinite;

    @keyframes slide {
      0% {
        left: 100%;
      }
      100% {
        left: -400%;
      }
    }

    .carouselCard {
      display: flex;
      justify-content: center;
      align-items: center;

      .carouselCardImg {
        height: 50rem;
        border-radius: 5rem;
      }
    }
  }
}
