@import "../../Assets/styleVariables.scss";

.headerContainer,
.otherHeaderContainer {
  font-family: $header-font;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100rem;
  width: 95vw;
  height: 11rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;

  border-bottom: 3px solid $pink-color;
  box-shadow: 0 2px 0 0 $blue-color;
  border-radius: 6rem;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.95);
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  .nameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    .name {
      color: $blue-color;
      font-size: 5rem;
      filter: saturate(150%);
      text-shadow: 5px 5px 0 $pink-color;
    }
    .titleContainer {
      .title {
        color: $pink-color;
        font-size: 2rem;
        text-transform: uppercase;
      }
    }
  }
  .linkContainerAbout,
  .linkContainerResume {
    text-decoration: none;
    font-size: 2rem;
  }
  .linkContainerAbout {
    margin-right: 2rem;
    color: $blue-color;
  }
  .linkContainerResume {
    margin-left: 2rem;
    color: $pink-color;
  }
}

.otherHeaderContainer {
  margin-top: -12rem;
}
