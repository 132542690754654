@import "../../Assets/styleVariables.scss";

.footerContainer {
  max-width: 100rem;
  margin: auto;
  margin-top: 2rem;
  border-top: 0.3rem solid $pink-color;
  border-radius: 3rem;
  padding: 2rem;

  .linksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}

@media (max-width: 1024px) {
}
@media (max-width: 768px) {
  .footerContainer {
    margin: 0;
  }
}
@media (max-width: 480px) {
}
