@import "../../Assets/styleVariables.scss";

.aboutContainer {
  width: 90vw;
  margin: auto;
  margin-top: 12rem;
  min-height: 80vh;
  max-width: 100rem;

  .subHeaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .subheaderItem {
      color: white;
      font-size: 1.2rem;
      text-transform: uppercase;

      &-active {
        @extend .subheaderItem;
        color: $pink-color;
        font-size: 1.3rem;
        border-bottom: 2px solid $blue-color;
        border-radius: 2rem;
        padding: 0.5rem;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
