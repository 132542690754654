@import "../../Assets/styleVariables.scss";

.introBannerContainer {
  height: 30rem;
  max-width: 100rem;
  margin: auto;
  margin-top: 15rem;
  margin-bottom: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: content-box;

  .introTextContainer {
    height: 100%;
    width: 50%;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;

    .introText {
      text-align: center;
      color: black;
      font-size: 2.5rem;
      background: linear-gradient(to bottom right, $blue-color, $pink-color);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .imgContainer {
    display: block;
    width: 50%;
    height: 100%;
    position: relative;
    border-radius: 3rem;
    overflow: hidden;

    .img {
      position: absolute;
      bottom: -15rem;
      width: 125%;
      filter: brightness(80%);
    }
  }
}

@media (max-width: 1024px) {
  .introBannerContainer {
    padding: 2rem;
    .imgContainer {
      height: 100%;
      .img {
        height: 100%;
        bottom: 0;
        width: auto;
      }
    }
  }
}
@media (max-width: 768px) {
  .introBannerContainer {
    padding: 2rem;
    flex-direction: column;
    height: auto;
    margin-bottom: 2rem;
    .introTextContainer {
      width: 100%;
    }
    .imgContainer {
      width: 100%;
      height: 50rem;

      .img {
        border-radius: 6rem;
      }
    }
  }
}
@media (max-width: 480px) {
}
