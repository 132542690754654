@import "../../Assets/styleVariables.scss";

.dropdown {
  max-width: 80rem;
  margin: auto;
  margin-bottom: 1.2rem;
  border: 2px solid $blue-color;
  border-radius: 3rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .question,
  .selected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    font-size: 2rem;
    gap: 0.5rem;
    background-color: black;
    border-radius: 8px;
    color: $blue-color;

    .questionText {
      margin-left: -2rem;
    }

    &:hover {
      background-color: black;
      color: $pink-color;
    }
  }

  .answer {
    height: 0;
    opacity: 0;
    transition: all 0.5s ease-in;

    .answerItem {
      height: 0;
    }
    &-active {
      opacity: 100;
      background-color: black;
      color: white;
      padding: 2rem;
      margin-top: 0.2rem;
      border-radius: 8px;
      font-size: 1.5rem;
      color: $blue-color;

      transition: all 0.5s ease-in;

      .answerItem {
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
    }
  }

  .selected {
    background-color: black;
    color: $pink-color;
    border-bottom: 2px solid $pink-color;
    width: 75%;

    .imgIndex {
      filter: invert(1);
    }
  }

  &:hover {
    cursor: pointer;
  }
}
