@import "../../Assets/styleVariables.scss";

.programmingContainer {
  min-height: 69vh;
  margin: auto;
  margin-top: 2rem;
  .intro {
    color: $blue-color;
    font-size: 2rem;
    text-align: center;
  }
  .accordionContainer {
    margin-top: 5rem;
  }
}
