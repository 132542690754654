@import "../../Assets/styleVariables.scss";

.homeContainer {
  .videoContainer {
    width: 100vw;
    height: 1rem;
    position: relative;
    z-index: -1;

    .videoTag {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .experienceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7rem;
    margin-top: -8rem;

    .experienceHeader {
      font-size: 5rem;
      margin-bottom: 3rem;
      background: linear-gradient(to bottom right, $blue-color, $pink-color);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .experienceIconContainer {
      display: flex;
      gap: 2rem;
      background: linear-gradient(to bottom right, $blue-color, $pink-color);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .manyMore {
    color: $blue-color;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    border-radius: 2rem;
    font-family: $header-font;
  }
}

@media (max-width: 1024px) {
  .homeContainer {
    .experienceContainer {
      .experienceIconContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        grid-column: 3/4;

        .experienceHeader {
          text-align: center;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .homeContainer {
    .experienceContainer {
      .experienceIconContainer {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;

        grid-column: 3/4;
      }
    }
  }
}
@media (max-width: 480px) {
}
